import LanguageService from './service/LanguageService'
export default {
  items: [
    // {
    //   name: LanguageService.lang.nav_dsnguoidung, //string
    //   url: '/admin/user/list', //string link url
    //   icon: 'icon-user', //string class
    //   meta: {
    //     allowAccess: ['*'],
    //     page: 'admin'
    //   },
    //   class: '',
    //   wrapper: {
    //     element: '',
    //     attributes: {}
    //   }
    // },
    {
      name: "User",
      url: '/admin/User/list',
      icon: 'icon-home',
      meta: {
        allowAccess: ['AdminSystem'],
        page: 'admin'
      },
    },
    {
      name: "Application",
      url: '/admin/Application/list',
      icon: 'icon-home',
      meta: {
        allowAccess: ['AdminSystem','Admin'],
        page: 'admin'
      },
    },
    {
      name: "Biểu mẫu",
      url: '/admin/Survey/list',
      icon: 'icon-home',
      meta: {
        allowAccess: ['*'],
        page: 'admin'
      },
    },
    // {
    //   name: "Dịch vụ du lịch",
    //   url: '/admin/DichVuChatLuongCao/list',
    //   icon: 'icon-like',
    //   meta: {
    //     allowAccess: ['Admin', 'Officer'],
    //     page: 'admin'
    //   },
    // },
    // {
    //   name: "Đánh giá",
    //   url: '/admin/DanhGia/list',
    //   icon: 'icon-star',
    //   meta: {
    //     allowAccess: ['Admin', 'Officer', 'TourAgent', 'TourGuide', 'User'],
    //     page: 'admin'
    //   },
    // },
    // {
    //   name: "Danh mục",
    //   url: '/admin/DanhMuc',
    //   icon: 'icon-folder',
    //   meta: {
    //     allowAccess: ['Admin', 'Officer'],
    //     page: 'admin'
    //   },
    //   children: [
    //     {
    //       name: "Trang trại",
    //       url: '/admin/DanhMuc/NhomTPDanhGia/list',
    //       icon: 'fa fa-caret-right',
    //       meta: {
    //         allowAccess: ['Officer', 'Admin', 'User'],
    //         page: 'admin'
    //       },
    //     },
    //   ]
    // },



  ]
}
